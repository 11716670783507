export const formMap = [
    {
        id: 'infoLogistiche',
        name: "infoLogistiche",
        title: 'INFORMAZIONI LOGISTICHE',
        subtitle: "",
        fields: [
            {
                id: 'pernottamento6',
                name: 'pernottamento6',
                label: <span>Abiti fuori Milano e Provincia e hai necessità di pernottare a Milano la notte di martedì 6 giugno?<br/>*Valido solo per chi è stato convocato per i meeting di area che si svolgeranno il 7 mattina (eventuali eccezioni saranno valutate individualmente da ogni Direzione sulla base di comprovate ragioni).</span>,
                type: 'radiogroup',
                required: true,
                validations: ['string', 'required'],
                precompiled: false,
                values: [
                    {
                        value: "si",
                        label: "Si"
                    },
                    {
                        value: "no",
                        label: "No"
                    }
                ],
                cols: {
                    xs: 12,
                    sm: 12
                },
                params: {
                    required: "Questo campo è obbligatorio"
                }
            },
            {
                id: 'pernottamento7',
                name: 'pernottamento7',
                label: 'Abiti fuori Milano e Provincia e hai necessità di pernottare a Milano la notte di mercoledì 7 giugno (eventuali eccezioni saranno valutate individualmente da ogni Direzione sulla base di comprovate ragioni)?',
                type: 'radiogroup',
                validations: ['string', "required"],
                required: true,
                precompiled: false,
                values: [
                    {
                        value: "si",
                        label: "Si"
                    },
                    {
                        value: "no",
                        label: "No"
                    }
                ],
                cols: {
                    xs: 12,
                    sm: 12
                },
                params: {
                    required: "Questo campo è obbligatorio"
                }
            }
        ]
    },
    {
        id: 'infoPersonali',
        title: 'Al fine di agevolare la procedura di check-in presso l’hotel, ti chiediamo di compilare i seguenti dati:',
        fields: [
            {
                id: 'nomeDocumento',
                name: 'nomeDocumento',
                label: 'Nome (come da documento)',
                type: 'text',
                validations: ['string', "when"],
                required: true,
                precompiled: false,
                params: {
                    when: [
                        ['pernottamento6', "pernottamento7"], {
                            is: (pernottamento6, pernottamento7) => pernottamento6 === "si" || pernottamento7 === "si",
                            then: (schema) => schema.required("Questo campo è obbligatorio")
                        }
                    ]
                }
            },
            {
                id: 'cognomeDocumento',
                name: 'cognomeDocumento',
                label: 'Cognome (come da documento)',
                type: 'text',
                validations: ['string', "when"],
                required: true,
                precompiled: false,
                params: {
                    when: [
                        ['pernottamento6', "pernottamento7"], {
                            is: (pernottamento6, pernottamento7) => pernottamento6 === "si" || pernottamento7 === "si",
                            then: (schema) => schema.required("Questo campo è obbligatorio")
                        }
                    ]
                }
            },
            {
                id: 'luogoNascita',
                name: 'luogoNascita',
                label: 'Luogo di nascita',
                type: 'text',
                validations: ['string', 'when'],
                required: true,
                precompiled: false,
                params: {
                    required: "Questo campo è obbligatorio",
                    when: [
                        ['pernottamento6', "pernottamento7"], {
                            is: (pernottamento6, pernottamento7) => pernottamento6 === "si" || pernottamento7 === "si",
                            then: (schema) => schema.required("Questo campo è obbligatorio")
                        }
                    ]
                }
            },
            {
                id: 'dataNascita',
                name: 'dataNascita',
                label: 'Data di nascita',
                type: 'date',
                validations: ["date", "nullable", 'when'],
                required: true,
                precompiled: false,
                disableFuture: true,
                params: {
                    when: [
                        ['pernottamento6', "pernottamento7"], {
                            is: (pernottamento6, pernottamento7) => pernottamento6 === "si" || pernottamento7 === "si",
                            then: (schema) => schema.required("Questo campo è obbligatorio").typeError("La data di nascita non è valida").max(new Date(), "La data di nascita non può essere nel futuro")
                        }
                    ]
                }
            },
            {
                id: 'tipoDocumento',
                name: 'tipoDocumento',
                label: 'Tipologia documento di viaggio',
                type: 'select',
                validations: ["string", 'when'],
                required: true,
                precompiled: false,
                values: [
                    {
                        value: "passaporto",
                        label: "Passaporto in corso di validità"
                    },
                    {
                        value: "cartaIdentita",
                        label: "Carta d'identità in corso di validità"
                    }
                ],
                params: {
                    required: "Questo campo è obbligatorio",
                    when: [
                        ['pernottamento6', "pernottamento7"], {
                            is: (pernottamento6, pernottamento7) => pernottamento6 === "si" || pernottamento7 === "si",
                            then: (schema) => schema.required("Questo campo è obbligatorio")
                        }
                    ]
                }
            },
            {
                id: 'numeroDocumento',
                name: 'numeroDocumento',
                label: 'N. documento',
                type: 'text',
                validations: ["string", 'when'],
                required: true,
                precompiled: false,
                params: {
                    required: "Questo campo è obbligatorio",
                    when: [
                        ['pernottamento6', "pernottamento7"], {
                            is: (pernottamento6, pernottamento7) => pernottamento6 === "si" || pernottamento7 === "si",
                            then: (schema) => schema.required("Questo campo è obbligatorio")
                        }
                    ]
                }
            },
            {
                id: 'dataEmissione',
                name: 'dataEmissione',
                label: 'Data di emissione',
                type: 'date',
                validations: ["date", "nullable", 'when'],
                required: true,
                precompiled: false,
                disableFuture: true,
                params: {
                    when: [
                        ['pernottamento6', "pernottamento7"], {
                            is: (pernottamento6, pernottamento7) => pernottamento6 === "si" || pernottamento7 === "si",
                            then: (schema) => schema.required("Questo campo è obbligatorio").typeError("La data di emissione non è valida").max(new Date(), "La data di emissione non può essere nel futuro")
                        }
                    ]
                }
            },
            {
                id: 'dataScadenza',
                name: 'dataScadenza',
                label: 'Data di scadenza',
                type: 'date',
                validations: ["date", "nullable", 'when'],
                required: true,
                precompiled: false,
                disablePast: true,
                params: {
                    when: [
                        ['pernottamento6', "pernottamento7"], {
                            is: (pernottamento6, pernottamento7) => pernottamento6 === "si" || pernottamento7 === "si",
                            then: (schema) => schema.required("Questo campo è obbligatorio")
                                .typeError("La data di scadenza non è valida")
                                .min(new Date(), "La data di scadenza non può essere nel passato")
                        }
                    ]
                }
            },
            {
                id: 'navettaAndata',
                name: 'navettaAndata',
                label: <span>Hai necessità della navetta dalla sede dei meeting di area fino alla location dell’evento?<br/>*Valido solo per chi è stato convocato per i meeting di area che si svolgeranno il 7 mattina.</span>,
                type: 'radiogroup',
                required: true,
                validations: ['string', 'when'],
                precompiled: false,
                values: [
                    {
                        value: "si",
                        label: "Si"
                    },
                    {
                        value: "no",
                        label: "No"
                    }
                ],
                cols: {
                    xs: 12,
                    sm: 12
                },
                params: {
                    when: [
                        ['pernottamento6', "pernottamento7"], {
                            is: (pernottamento6, pernottamento7) => pernottamento6 === "si" && pernottamento7 !== "",
                            then: (schema) => schema.required("Questo campo è obbligatorio")
                        }
                    ]
                },
                conditions: {
                    type: "or",
                    predicates: [
                        {
                            id: "pernottamento6",
                            value: "si"
                        }
                    ]
                }
            },
            {
                id: 'navettaRitorno',
                name: 'navettaRitorno',
                label: <span>Hai necessità della navetta di rientro dalla location dell’evento al tuo hotel?</span>,
                type: 'radiogroup',
                required: true,
                validations: ['string', 'when'],
                precompiled: false,
                values: [
                    {
                        value: "si",
                        label: "Si"
                    },
                    {
                        value: "no",
                        label: "No"
                    }
                ],
                cols: {
                    xs: 12,
                    sm: 12
                },
                params: {
                    when: [
                        ['pernottamento6', "pernottamento7"], {
                            is: (pernottamento6, pernottamento7) => pernottamento6 === "si" || pernottamento7 === "si",
                            then: (schema) => schema.required("Questo campo è obbligatorio")
                        }
                    ]
                },
                conditions: {
                    type: "or",
                    predicates: [
                        {
                            id: "pernottamento6",
                            value: "si"
                        },
                        {
                            id: "pernottamento7",
                            value: "si"
                        }
                    ]
                }
            },
            {
                id: 'note-1',
                name: 'note-1',
                type: 'note',
                label: <span>N.B.
                    <br/>
                     Nel caso tu abbia richiesto il pernottamento riceverai indicazioni successive.
                    <br/>
                     Per gli <b>spostamenti</b>, organizzati come fai di consueto con le trasferte.
                    <br/>
                    Ti ricordiamo che anche per l’E-Day 2023 è disponibile il portale Egencia</span>,
                cols: {
                    xs: 12,
                    sm: 12
                }
            },
            {
                id: 'note-2',
                name: 'note-2',
                type: 'note',
                label: <span><br/>Per maggiore supporto, contatta la Segreteria Organizzativa dell’evento scrivendo a <a
                    href={'mailto:segreteria@eday2023.it'}>segreteria@eday2023.it</a><br/><br/></span>,
                cols: {
                    xs: 12,
                    sm: 12
                }
            }
        ],
        conditions: {
            type: "or",
            predicates: [
                {
                    id: "pernottamento6",
                    value: "si"
                },
                {
                    id: "pernottamento7",
                    value: "si"
                }
            ]
        }
    },
    {
        id: 'privacy',
        name: "privacy",
        title: '',
        subtitle: "",
        fields: [
            {
                id: 'privacy',
                name: 'privacy',
                label: <span>Ho letto e compreso termini e condizioni per il trattamento dei miei dati personali riportati nell’<a
                    href={'/Informativa_privacy_E-Day_2023.pdf'} target={'_blank'}>informativa privacy</a></span>,
                type: 'checkbox',
                validations: ['bool',"required", "oneOf"],
                required: true,
                precompiled: false,
                cols: {
                    xs: 12,
                    sm: 12
                },
                params: {
                    oneOf: [
                        [true], "Questo campo è obbligatorio"
                    ]
                }
            }
        ]
    }
]
