import React from "react";
import {
    Box, Button, Checkbox, CircularProgress,
    FormControl,
    FormControlLabel, FormHelperText, FormLabel,
    Radio,
    RadioGroup,
    Typography
} from "@mui/material";
import useAxios from "axios-hooks";
import {API_URL} from "../config";
import {useParams} from "react-router-dom";
import {Formik} from "formik";
import * as yup from "yup";

export const FormNonPartecipo = ({motivazione, handleChangeMotivazione, partecipazione, handleSetPartecipazione}) => {
    const {hash} = useParams()
    const [{data, loading, error}, executePost] = useAxios(
        {
            url: `${API_URL}register/${hash}`, method: 'POST'
        }, {manual: true}
    )

    const handleSubmit = (values) => {
        let data = {
            partecipazione: "no",
            ...values
        }
        executePost({
            data: data
        })
            .then(() => handleSetPartecipazione('no'))
            .catch()

    }

    const validationSchema = yup.object().shape({
        motivazione: yup.string().required('Questo campo è obbligatorio'),
        privacy: yup.boolean().oneOf([true], 'Questo campo è obbligatorio')
    })

    return (
        <Box>
            <Typography textAlign={'center'} variant={'h6'}>
                Grazie per il feedback, le tue risposte sono state inviate con successo!
            </Typography>
            {(partecipazione !== 'no' && !data) &&
                <Formik
                    validationSchema={validationSchema}
                    initialValues={{motivazione: '', privacy: false}}
                    validateOnChange={false}
                    validateOnBlur={false}
                    error
                    onSubmit={values => {
                        handleSubmit(values)
                    }}
                >
                    {(formik) =>
                        <form onSubmit={formik.handleSubmit}>
                            <FormControl fullWidth sx={{py: 2}} error={!!(formik.touched.motivazione && formik.errors.motivazione)}>
                                <FormLabel id="label-motivazione" sx={{color:'black'}}>Ti preghiamo di indicare il motivo:</FormLabel>
                                <RadioGroup
                                    id="motivazione"
                                    name={'motivazione'}
                                    value={formik.values.motivazione}
                                    onChange={formik.handleChange}
                                >
                                    <FormControlLabel value="impegni" control={<Radio/>}
                                                      label="Impegni precedenti di lavoro preventivamente autorizzati dal mio responsabile"/>
                                    <FormControlLabel value="ferie" control={<Radio/>}
                                                      label="Ferie preventivamente autorizzate dal mio responsabile"/>
                                    <FormControlLabel value="altro" control={<Radio/>}
                                                      label="Altro: infortunio, malattia, congedi…"/>
                                </RadioGroup>
                                <FormHelperText>{formik.touched.motivazione && formik.errors.motivazione}</FormHelperText>
                            </FormControl>
                            <FormControl error={!!(formik.touched.privacy && formik.errors.privacy)}>
                                <FormControlLabel control={<Checkbox id={'privacy'} name={'privacy'} onChange={formik.handleChange} checked={formik.values.privacy}/>}
                                                  label={<span>Ho letto e compreso termini e condizioni per il trattamento dei miei dati personali riportati nell’<a
                                                      href={'/Informativa_privacy_E-Day_2023.pdf'} target={'_blank'}>informativa privacy</a></span>}/>
                                <FormHelperText>{formik.touched.privacy && formik.errors.privacy}</FormHelperText>
                            </FormControl>
                            <Box sx={{textAlign: 'center'}}>
                                {loading ? <CircularProgress/> : <Button type={'submit'} variant={'contained'}>
                                    Invia
                                </Button>}
                            </Box>
                        </form>}
                </Formik>
            }
        </Box>
    )
}
