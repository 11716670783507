import React from "react";
import {Box} from "@mui/material";

export const Home = () => {
  return(
      <Box sx={{height:'max(30rem, 100vh)', width:'100%'}}>
          <img style={{width:'100%', height:'100%', objectFit:'contain'}} src={require('../assets/header.jpg')} alt={'logo'}/>
      </Box>
  )
}
