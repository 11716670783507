import React, {useEffect} from "react";
import {Box, Button, CircularProgress, Container, Stack, Typography} from "@mui/material";
import useAxios from "axios-hooks";
import {API_URL} from "../config";
import {useNavigate, useParams} from "react-router-dom";
import {ArrowBack} from "@mui/icons-material";
import {FormNonPartecipo} from "../components/FormNonPartecipo";
import {FormPartecipo} from "../components/FormPartecipo";

export const Register = () => {
    const {hash} = useParams()
    const navigate = useNavigate()
    const [{data: userData, loading, error}, refetch] = useAxios(`${API_URL}users/${hash}/info`, {manual: false})
    const [partecipo, setPartecipo] = React.useState(null)
    const [motivazione, setMotivazione] = React.useState('')
    const [partecipazione, setPartecipazione] = React.useState('')

    useEffect(() => {
        if (error) {
            navigate('/error', {replace: true})
        }
        if (userData) {
            setPartecipazione(userData.partecipazione ?? '')
        }
    }, [userData, loading, error])

    const handleBack = () => {
        setPartecipo(null)
        setMotivazione('')
    }

    const handleChangeMotivazione = (event) => {
        setMotivazione(event.target.value)
    }

    const handleSetPartecipazione = (partecipazione) => {
        setPartecipazione(partecipazione)
    }

    return (
        <Container sx={{pb: 2}}>
            <img src={require('../assets/header.jpg')} alt={'header'} style={{width: '100%'}}/>
            {loading ?
                <Box sx={{textAlign: 'center'}}>
                    <CircularProgress/>
                </Box>
                :
                userData?.allowRegistration ?
                    <Container>
                        {(partecipo === null && partecipazione === '') && <Stack spacing={2} alignItems={'center'}>
                            <Button variant={'contained'} sx={{width: 'min(20rem, 100%)', fontSize: '1.1rem'}}
                                    onClick={() => setPartecipo(true)}>
                                Partecipo
                            </Button>
                            <Button variant={'outlined'} color={'secondary'} sx={{width: 'min(16rem, 100%)'}} onClick={() => setPartecipo(false)}>
                                Non partecipo
                            </Button>
                        </Stack>}
                        {(partecipo !== null && partecipazione === '') &&
                            <Box>
                                <Button variant={'outlined'} color={'secondary'} onClick={handleBack} startIcon={<ArrowBack/>}>Indietro</Button>
                            </Box>
                        }
                        {(partecipo || partecipazione === 'si') &&
                            <FormPartecipo partecipazione={partecipazione} handleSetPartecipazione={handleSetPartecipazione} userData={userData}/>}
                        {(partecipo === false || partecipazione === 'no') &&
                            <FormNonPartecipo partecipazione={partecipazione} handleSetPartecipazione={handleSetPartecipazione} handleChangeMotivazione={handleChangeMotivazione}
                                              motivazione={motivazione}/>}
                    </Container>
                    :
                    <Box sx={{pt:2}}>
                        <Typography variant={'h4'} sx={{textAlign: 'center', pb: 4}}>
                            Le iscrizioni sono chiuse.
                        </Typography>
                        <Typography textAlign={'center'}>
                            Per qualsiasi esigenza puoi contattare la segreteria organizzativa all'indirizzo <a href={'mailto:segreteria@eday2023.it'}>segreteria@eday2023.it</a>.
                        </Typography>
                    </Box>
            }
        </Container>
    )
}
