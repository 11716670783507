import './App.css';
import {Route, Routes} from "react-router-dom";
import {Home} from "./pages/Home";
import {Register} from "./pages/Register";
import {Error} from "./pages/Error";
import {Logistic} from "./pages/Logistic";

function App() {
    return (
        <div>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path={'/register/:hash'} element={<Register/>}/>
                <Route path={'/logistic/:hash'} element={<Logistic/>}/>
                <Route path={'/error'} element={<Error/>}/>
            </Routes>
        </div>
    );
}

export default App;
