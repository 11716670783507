import React from "react";
import {Box, Container, Typography} from "@mui/material";

export const Error = () => {
    return(
        <Container sx={{pb: 2}}>
            <img src={require('../assets/header.jpg')} alt={'header'} style={{width: '100%'}}/>
            <Box sx={{textAlign: 'center'}}>
                <Typography variant={'h5'} color={'red'} fontWeight={'bold'}>Il link utilizzato non è corretto</Typography>
            </Box>
        </Container>
    )
}
