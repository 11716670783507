import React, {useEffect} from "react";
import {Box, Button, CircularProgress, Container, Typography} from "@mui/material";
import useAxios from "axios-hooks";
import {API_URL} from "../config";
import {useNavigate, useParams} from "react-router-dom";
import {TestoLogistica} from "../components/TestoLogistica";
import {ArrowBack} from "@mui/icons-material";
import {FormLogistica} from "../components/FormLogistica";

export const Logistic = () => {
    const {hash} = useParams()
    const [{data: userData, loading, error}, refetch] = useAxios(`${API_URL}users/${hash}/info`, {manual: false})
    const navigate = useNavigate()
    const [logistica, setLogistica] = React.useState(false)
    const [step, setStep] = React.useState(0)

    useEffect(() => {
        if (error) {
            navigate('/error', {replace: true})
        }
        if (userData) {
            setLogistica(userData.logistic ?? false)
        }
    }, [userData, loading, error])

    const handleSetStep = (step) => {
        setStep(step)
    }

    const handleSetLogistica = (logistica) => {
        setLogistica(logistica)
    }

    return (
        <Container sx={{pb: 2}}>
            <img src={require('../assets/header.jpg')} alt={'header'} style={{width: '100%'}}/>
            {
                loading ?
                    <Box sx={{textAlign: 'center'}}>
                        <CircularProgress/>
                    </Box>
                    :
                    <Container maxWidth={'md'}>
                        {
                            !logistica ?
                                /*step === 0 ?
                                    <Box>
                                        <TestoLogistica userData={userData}/>
                                        <Box sx={{textAlign: 'center', py: 3}}>
                                            <Button variant={'contained'} sx={{fontSize: '1.3rem'}}
                                                    onClick={() => handleSetStep(1)}>
                                                COMPILA IL FORM
                                            </Button>
                                        </Box>
                                    </Box>
                                    :
                                    <Box>
                                        <Box>
                                            <Button variant={'outlined'} color={'secondary'} onClick={() => handleSetStep(0)} startIcon={<ArrowBack/>}>Indietro</Button>
                                        </Box>
                                        <FormLogistica handleSetLogistica={handleSetLogistica}/>
                                    </Box>*/
                                <Box sx={{py: 4}}>
                                    <Typography textAlign={'center'} variant={'h6'}>
                                        <b>Le prenotazioni del servizio navetta sono chiuse.</b>
                                    </Typography>
                                        <br/>
                                    <Typography textAlign={'center'}>
                                        Per qualsiasi esigenza puoi contattare la Segreteria Organizzativa
                                        all’indirizzo <a href={'mailto:segreteria@eday2023.it'}
                                                         target={'_blank'}>segreteria@eday2023.it</a>
                                    </Typography>
                                </Box>

                                :
                                <Box sx={{py: 4}}>
                                    <Typography textAlign={'center'} variant={'h6'} fontWeight={'bold'}>
                                        Grazie per il feedback, le tue risposte sono state inviate con successo!
                                    </Typography>
                                </Box>
                        }


                    </Container>
            }
        </Container>
    )
}
