import React, {useEffect} from "react";
import {Box, Button, CircularProgress, Container, Snackbar, TextField, Typography} from "@mui/material";
import {Formik} from "formik";
import {FormContent} from "./FormContent";
import * as yup from "yup";
import {formMap} from "../assets/data/formMap";
import {createYupSchema} from "../functions/yupSchemaCreator";
import {createValidationSchema} from "../functions/createValidationScheme";
import axios from "axios";
import useAxios from "axios-hooks";
import {API_URL} from "../config";
import {useParams} from "react-router-dom";
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const FormPartecipo = ({partecipazione, userData, handleSetPartecipazione}) => {
    const {hash} = useParams()
    const [nome, setNome] = React.useState(userData?.nome || '')
    const [cognome, setCognome] = React.useState(userData?.cognome || '')
    const [email, setEmail] = React.useState(userData?.email || '')
    const [{data, loading, error}, executePost] = useAxios(
        {
            url: `${API_URL}register/${hash}`, method: 'POST'
        }, {manual: true}
    )

    const initialValues = () => {
        let d = {}
        formMap.map(s => {
            return s.fields.filter(f => f.type !== 'note').forEach(f => {
                    let key = f.id
                    if (f.type === 'date' || f.type === 'datetime' || f.type === 'time') {
                        d[key] = null
                    } else if (f.type === 'checkbox') {
                        d[key] = false
                    } else {
                        d[key] = ''
                    }
                }
            )
        })
        return d
    }

    const yupSchema = () => {
        let fields = []
        formMap.map(s => {
            return s.fields.filter(f => f.type !== 'note').forEach(f => {
                fields.push(f)
            })
        })
        let schema = createValidationSchema(fields)
        return schema
    }

    const validateSchema = yupSchema();

    const sendData = async (values) => {
        let data = {
            partecipazione: "si",
            ...values
        }
        executePost({
            data: data
        })
            .then(() => handleSetPartecipazione('si'))
            .catch()
    }

    return (
        <Box>
            <Snackbar open={error} autoHideDuration={6000}>
                <Alert severity="error" sx={{ width: '100%' }}>
                    C'è stato un errore, si prega di riprovare
                </Alert>
            </Snackbar>
            <Container maxWidth={'md'}>
                {(partecipazione!=='' || data) ?
                    <Typography textAlign={'center'} variant={'h5'} fontWeight={'bold'}>
                        Grazie per aver compilato la scheda di iscrizione, i tuoi dati sono stati registrati con successo!
                        <br/>
                        <br/>
                        Ti aspettiamo all’E-Day 2023.
                        <br/>
                        STAY GOLD!
                    </Typography>
                    :
                    <Box sx={{pt:0, pb: 2}}>
                        <Box>
                            <Typography sx={{pb:2}} textAlign={'center'} variant={'h5'} fontWeight={'bold'}>
                                Ti aspettiamo il 7 giugno!
                            </Typography>
                            <Typography textAlign={'center'} variant={'h6'}>
                                Compila i campi sottostanti per completare la tua iscrizione all’E-Day 2023.
                            </Typography>
                            <Box sx={{py: 1}}>
                                <Typography>
                                    <b>Nome</b>: {nome}
                                    <br/>
                                    <b>Cognome</b>: {cognome}
                                    <br/>
                                    <b>Email</b>: {email}
                                </Typography>
                            </Box>
                        </Box>
                        <Formik
                            validationSchema={validateSchema}
                            initialValues={{
                                ...initialValues()
                            }}
                            validateOnChange={true}
                            validateOnBlur={false}
                            validateOnMount={false}
                            onSubmit={values => {
                                sendData(values)
                            }}
                        >
                            {(formik) =>
                                <form onSubmit={formik.handleSubmit}>
                                    <FormContent formik={formik} formMap={formMap}/>
                                    <Box sx={{textAlign: 'center', pt: 2}}>
                                        {loading ? <CircularProgress/> :
                                            <Button variant={'contained'} type={'submit'}>
                                                Registrati
                                            </Button>
                                        }
                                    </Box>
                                </form>
                            }
                        </Formik>
                    </Box>}
            </Container>
        </Box>
    )
}
